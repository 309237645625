<template>
  <div class="articles">
    <base-icon class="icon" :name="$vuetify.breakpoint.width > 800 ? 'statya' : 'statyatablet' " />
  </div>
</template>

<script>
export default {
  name: 'Articles',
};
</script>

<style lang="sass">
  .articles
    background-image: url('/images/back.svg')
    background-size: cover
    .icon
      width: 100vw
      height: calc(100vh - 50px)
</style>
